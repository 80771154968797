import { render, staticRenderFns } from "./createLaboratorio.vue?vue&type=template&id=5fb0c045&scoped=true&"
import script from "./createLaboratorio.vue?vue&type=script&lang=js&"
export * from "./createLaboratorio.vue?vue&type=script&lang=js&"
import style0 from "./createLaboratorio.vue?vue&type=style&index=0&id=5fb0c045&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fb0c045",
  null
  
)

export default component.exports